<template>
  <div>
    <div class="help el-icon-search">
      <a
        href="https://afewxu3liy.jxn2cm2g.com/03f9339975a075djkfle-kelif4359077a474d17c33ee890744c5957f8b438e8938e73ee0fab85009186b5fd6 "
        >帮助
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 999;
  width: 100px;
  height: 50px;
  line-height: 50px;
  background-color: #00be40;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>