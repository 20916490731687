<template>
  <div>
    <div class="head">
      <div class="header">
        <div class="left">
          <a
            href="https://afewxu3liy.jxn2cm2g.com/03f9339975a075djkfle-kelif4359077a474d17c33ee890744c5957f8b438e8938e73ee0fab85009186b5fd6"
            >联系客服</a
          >
        </div>
        <div class="right">
          <div class="accout"><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">开设账户</a></div>
          <div class="login"><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">登录</a></div>
        </div>
      </div>
    </div>
    <div class="logocontent">
      <div class="headBottom">
        <div class="bottomleft">
          <img class="logo" src="../images/pc/logo.svg" alt="" />
          <p style="margin-left: 20px">在线游戏经济商</p>
        </div>
        <div class="bottomright">
          <div>
            <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">游戏</a>
          </div>
          <div><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">合作伙伴</a></div>
          <div><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">优惠活动</a></div>
          <div>
            <a
              href="https://afewxu3liy.jxn2cm2g.com/03f9339975a075djkfle-kelif4359077a474d17c33ee890744c5957f8b438e8938e73ee0fab85009186b5fd6 "
              >联系我们</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.head {
  height: 40px;
  width: 100%;
  background-color: #000;
}
.header {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  height: 40px;
}
.accout {
  background-color: #22272b;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.login {
  background-color: #507500;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.logocontent {
  height: 80px;
  background-color: #132637;
  opacity: 0.8;
  box-shadow: 5px 5px 5px #000;
  z-index: 999;
  position: relative;
}
.headBottom {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 19px;
}
.logo {
  width: 60px;
  height: 60px;
}
.bottomleft {
  display: flex;
  align-items: center;
  height: 80px;
}
.bottomright {
  display: flex;
  align-items: center;
  height: 80px;
}
.bottomright > div {
  padding: 0 15px;
}
</style>