<template>
  <div class="PC_content">
    <div v-if="isShow">
      <Mobie />
    </div>
    <div v-else>
      <Header />
      <div class="banner">
        <el-carousel trigger="click" height="600px" indicator-position="none">
          <el-carousel-item v-for="item in swiperList" :key="item.id">
            <img class="bannerImg" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="mianConten">
        <div class="boxmove">
          <div class="contentop">
            <div class="name">
              <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
                >亚洲客户请到官方合作伙伴bet365</a
              >
            </div>
            <div class="cash">
              <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
                >出入金</a
              >
            </div>
            <div class="serves">
              <a
                href="https://afewxu3liy.jxn2cm2g.com/03f9339975a075djkfle-kelif4359077a474d17c33ee890744c5957f8b438e8938e73ee0fab85009186b5fd6 "
                >在线客服</a
              >
            </div>
          </div>
          <div class="comtentBotm">
            <div class="imgBo">
              <router-link to="/about">
                <a href=""
                  ><img src="../images/pc/partnership-leicester.png" alt=""
                /></a>
              </router-link>
            </div>
            <div class="imgBo">
              <p>您的赛场由您掌控</p>
              <img
                style="width: 120px"
                src="../images/pc/Joint_wo-FBS-vertical-FBS_Lleicester-zh-Positive.svg"
                alt=""
              />
              <img
                style="width: 100px"
                src="../images/pc/cn_logo-without-vertical-color-black.svg"
                alt=""
              />
            </div>
            <div class="imgBo">
              <router-link to="/friendship">
                <img src="../images/pc/partnership-barca.png" alt=""
              /></router-link>
            </div>
          </div>
          <div class="words">在线游戏交易亚洲客户请到官方合作伙伴bet365</div>
        </div>
      </div>
      <div class="lastEnd">
        <div class="lastEndContent">
          <div class="lastEndContent_left">您的赛场由您掌控</div>
          <div class="lastEndContent_right">
            <img
              style="width: 200px"
              src="../images/pc/Joint_wo-FBS-flag-FBS_Lleicester-zh-Positive.svg"
              alt=""
            />
            <img
              style="width: 200px; margin-left: 30px"
              src="../images/pc/cn_logo-without-color-black.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
      <Help />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
import Mobie from "../components/mobie.vue";
import Help from "../components/help.vue";
export default {
  components: {
    Footer,
    Header,
    Mobie,
    Help,
  },
  data() {
    return {
      swiperList: [
        { id: 1, url: require("../images/pc/3HJjRDF4xGHb5ncL.jpg") },
        { id: 2, url: require("../images/pc/3WIGhkT8WAHI-Vs8.jpg") },
        { id: 3, url: require("../images/pc/CHFKY1L1s3Z7gXL1.jpg") },
        { id: 4, url: require("../images/pc/swiper.jpg") },
      ],
      isShow: "",
    };
  },
  created() {
    this._isMobile();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag == null) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
      return flag;
    },
  },
};
</script>
<style  scoped>
.banner {
  width: 100%;
  position: relative;
  top: -80px;
}
.bannerImg {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.bannner /deep/ .el-carousel__container {
  height: 100% !important;
}
.mianConten {
  width: 100%;
  height: 500px;
  background-color: #384147;
  position: relative;
  top: -100px;
}
.boxmove {
  position: relative;
  top: -80px;
  z-index: 999;
}
.contentop {
  width: 1200px;
  margin: 0 auto;
  height: 74px;
  background-color: #507500;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 19px;
  text-align: center;
  opacity: 0.8;
}
.name {
  flex: 2;
  background-color: #f04a00;
  height: 74px;
  line-height: 74px;
}
.cash,
.serves {
  flex: 1;
  height: 74px;
  line-height: 74px;
  background-color: #000;
}
.comtentBotm {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
.words {
  font-size: 54px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}
.lastEnd {
  height: 100px;
  width: 100%;
  color: #000;
  font-size: 27px;
  font-weight: 600;
}
.lastEndContent {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: -100px;
}
.lastEndContent_left {
  height: 100px;
  line-height: 100px;
}
.lastEndContent_right {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>